export const MICROSITE_TABS = Object.freeze([
	'ALL_WIDGETS',
	'BANNER_HERO',
	'BANNER_SHORTCUT',
	'BANNER_HIGHLIGHT',
	'BANNER_CONTENT',
]);

export const MICROSITE_COMPONENTS = Object.freeze({
	ALL_WIDGETS: {
		title: 'All widgets',
		component: 'WidgetList',
		routerTo: 'MicrositeWidgets',
		requiredCategory: true,
		otherWidgetDescription: 'All widgets are shown on Apple site, you can create widgets, re-order widgets and set visibility.',
	},
	BANNER_HERO: {
		title: 'Hero banner',
		component: 'BannerGroupList',
		routerTo: 'MicrositeBannerHeroList',
		routerToCreate: 'MicrositeBannerHeroCreate',
		routerToEdit: 'MicrositeBannerHeroEdit',
	},
	BANNER_SHORTCUT: {
		title: 'Shortcut',
		component: 'BannerGroupList',
		routerTo: 'MicrositeBannerShortcutList',
		routerToCreate: 'MicrositeBannerShortcutCreate',
		routerToEdit: 'MicrositeBannerShortcutEdit',
	},
	BANNER_HIGHLIGHT: {
		title: 'Highlight banner',
		component: 'BannerGroupList',
		routerTo: 'MicrositeBannerHighlightList',
		routerToCreate: 'MicrositeBannerHighlightCreate',
		routerToEdit: 'MicrositeBannerHighlightEdit',
	},
	BANNER_CONTENT: {
		title: 'Content banner',
		component: 'BannerGroupList',
		routerTo: 'MicrositeBannerContentList',
		routerToCreate: 'MicrositeBannerContentCreate',
		routerToEdit: 'MicrositeBannerContentEdit',
	},
});
